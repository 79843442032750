export const TRAMITES_REQUEST = 'TRAMITES_REQUEST';
export const TRAMITES_FAIL = 'TRAMITES_FAIL';
export const TRAMITES_GET_BY_ID = 'TRAMITES_GET_BY_ID';
export const TRAMITES_CREATE = 'TRAMITES_CREATE';
export const TRAMITES_UPSERT_INPUTS_VALUES = 'TRAMITES_UPSERT_INPUTS_VALUES';
export const TRAMITES_CHECK_LAST_PROCEDURE = 'TRAMITES_CHECK_LAST_PROCEDURE';
export const TRAMITES_UPLOAD_PAYMENT = 'TRAMITES_UPLOAD_PAYMENT';
export const TRAMITES_GET_BY_USER_ID = 'TRAMITES_GET_BY_USER_ID';
export const TRAMITES_GET_TIPOS_TRAMITE = 'TRAMITES_GET_TIPOS_TRAMITE';
export const TRAMITES_CANCELAR = 'TRAMITES_CANCELAR';

export const TIPOTRAMITE_GET_BY_ID_EXTERNO = 'TIPOTRAMITE_GET_BY_ID_EXTERNO';
export const TRAMITES_CREAR_EXTERNO = 'TRAMITES_CREAR_EXTERNO';
export const TRAMITES_GET_EXTERNO = 'TRAMITES_GET_EXTERNO';

export const TRAMITES_ELEGIR_CUOTAS = 'TRAMITES_ELEGIR_CUOTAS';
export const TRAMITES_BORRAR_CUOTAS = 'TRAMITES_BORRAR_CUOTAS';

export const TRAMITE_GET_MATRICULADOS = 'TRAMITE_GET_MATRICULADOS';

export const TRAMITES_RESET = 'TRAMITES_RESET';

export const TRAMITE_IMPRIMIR = 'TRAMITE_IMPRIMIR';
