import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import CambiarPassword from './CambiarPassword';
import PropTypes from 'prop-types';
import CambioEmail from './CambioEmail';

const Configuracion = ({ email }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  return (
    <>
      <div className="d-grid gap-2">
        <Button variant="" size="sg" onClick={() => setOpenModal2(true)}>
          Modificar Correo Electrónico
        </Button>
        <CambioEmail openModal={openModal2} setOpenModal={setOpenModal2} />

        <Button variant="" size="sg" onClick={() => setOpenModal(true)}>
          Modificar Contraseña
        </Button>
        <CambiarPassword
          openModal={openModal}
          setOpenModal={setOpenModal}
          email={email}
        />
      </div>
    </>
  );
};
Configuracion.propTypes = {
  email: PropTypes.string.isRequired
};
export default Configuracion;
