import React from 'react';
import PropTypes from 'prop-types';
import { requisitos } from 'assets/json/requisitosTramites';

const Requisitos = ({ tipoTramiteId }) => {
  console.log(tipoTramiteId);
  return (
    <ul>
      {requisitos[tipoTramiteId].map((r, index) => (
        <li key={index}>{r}</li>
      ))}
    </ul>
  );
};
Requisitos.propTypes = {
  tipoTramiteId: PropTypes.number.isRequired
};
export default Requisitos;
