import React, { useState } from 'react';
import { Button, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormModificarDatos } from './components/FormModificarDatos';
import Configuracion from './components/Configuracion';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { tramiteGetByUserId } from 'redux/actions/tramite';
import { useEffect } from 'react';
import { FormModificarDatosComercial } from './components/FormModificarDatosComerciales';

export const ModalConfiguracion = ({
  openModalDatos,
  setOpenModalDatos,
  user
}) => {
  const dispatch = useDispatch();
  const { tramites } = useSelector(state => state.tramiteReducer);
  const [key, setKey] = useState('first');
  const [hasDeclaration, setHasDeclaration] = useState(false);
  const [canEditComercialActivity, setCanEditComercialActivity] =
    useState(false);

  useEffect(() => {
    user && dispatch(tramiteGetByUserId(user.id));
  }, [user.id]);

  useEffect(() => {
    setHasDeclaration(
      Array.isArray(tramites.results) &&
        tramites.results.some(tramite => {
          return (
            tramite.titulo === 'Declaración Jurada de Actividad Comercial' &&
            tramite.estado === 'aprobado'
          );
        })
    );
  }, [tramites]);

  useEffect(() => {
    if (hasDeclaration) {
      setCanEditComercialActivity(true);
    }
    setCanEditComercialActivity(false);
  }, [hasDeclaration]);

  return (
    <Modal
      show={openModalDatos}
      onHide={() => setOpenModalDatos(false)}
      contentClassName="border"
      centered
      size="lg"
    >
      <Modal.Header
        closeButton
        className="bg-light px-card border-bottom-0 d-flex align-items-start"
      >
        <Modal.Title as="h5">Actualizar Perfil</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: '600px' }}>
        <Tab.Container activeKey={key} onSelect={k => setKey(k)}>
          <Row>
            <Col sm={4}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    className={classNames('px-2', {
                      'text-white': key === 'first',
                      'text-black': key !== 'first'
                    })}
                  >
                    Datos Personales
                  </Nav.Link>
                </Nav.Item>
                {hasDeclaration && (
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      className={classNames('px-2', {
                        'text-white': key === 'second',
                        'text-black': key !== 'second'
                      })}
                    >
                      Datos Comerciales
                    </Nav.Link>
                  </Nav.Item>
                )}

                <Nav.Item>
                  <Nav.Link
                    eventKey="third"
                    className={classNames('px-2', {
                      'text-white': key === 'third',
                      'text-black': key !== 'third'
                    })}
                  >
                    Configuración
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <FormModificarDatos user={user} />
                </Tab.Pane>

                {hasDeclaration && (
                  <Tab.Pane eventKey="second">
                    <FormModificarDatosComercial user={user} />
                  </Tab.Pane>
                )}

                <Tab.Pane eventKey="third">
                  <Configuracion email={user.email} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="ms-1"
          size="sm"
          variant="thirdary"
          onClick={() => setOpenModalDatos(false)}
        >
          <span>Cerrar</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
ModalConfiguracion.propTypes = {
  openModalDatos: PropTypes.bool,
  setOpenModalDatos: PropTypes.func,
  user: PropTypes.object
};
