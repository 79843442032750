import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { checkLogged, updateUsuarioDatos } from 'redux/actions/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

const datosIniciales = {
  emailComercial: [{ value: '' }],
  telefonoComercial: [{ value: '' }],
  telefonoCasaCentral: '',
  telefonoSucursal1: '',
  telefonoSucursal2: ''
};

export const FormModificarDatosComercial = ({ user }) => {
  const [formData, setFormData] = useState({ ...datosIniciales });
  const [loading, setLoading] = useState(false);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Solo actualizar formData cuando los datos del usuario estén disponibles por primera vez
    if (user?.datos && !userDataLoaded) {
      setFormData({
        emailComercial: Array.isArray(user.datos.emailComercial)
          ? user.datos.emailComercial.map(email => ({ value: email.value }))
          : [{ value: '' }],
        telefonoComercial: Array.isArray(user.datos.telefonoComercial)
          ? user.datos.telefonoComercial.map(telefono => ({
              value: telefono.value
            }))
          : [{ value: '' }],
        telefonoCasaCentral: user.datos.telefonoCasaCentral?.value || '',
        telefonoSucursal1: user.datos.telefonoSucursal1?.value || '',
        telefonoSucursal2: user.datos.telefonoSucursal2?.value || ''
      });
    }
  }, [user, userDataLoaded]);

  const handleAddEmailComercial = () => {
    setFormData(prev => ({
      ...prev,
      emailComercial: [...prev.emailComercial, { value: '' }]
    }));
  };

  const handleRemoveEmailComercial = index => {
    setFormData(prev => ({
      ...prev,
      emailComercial: prev.emailComercial.filter((email, i) => i !== index)
    }));
  };

  const handleAddTelefonoComercial = () => {
    setFormData(prev => ({
      ...prev,
      telefonoComercial: [...prev.telefonoComercial, { value: '' }]
    }));
  };

  const handleRemoveTelefonoComercial = index => {
    setFormData(prev => ({
      ...prev,
      telefonoComercial: prev.telefonoComercial.filter(
        (telefono, i) => i !== index
      )
    }));
  };

  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true);

      const cleanedEmailComercial = formData.emailComercial.filter(
        email => email.value.trim() !== ''
      );
      const cleanedTelefonoComercial = formData.telefonoComercial.filter(
        telefono => telefono.value.trim() !== ''
      );

      await dispatch(
        updateUsuarioDatos(user.id, {
          ...user.datos,
          emailComercial: cleanedEmailComercial.map(email => ({
            value: email.value
          })),
          telefonoComercial: cleanedTelefonoComercial.map(telefono => ({
            value: telefono.value
          })),
          telefonoCasaCentral: { value: formData.telefonoCasaCentral.trim() },
          telefonoSucursal1: { value: formData.telefonoSucursal1.trim() },
          telefonoSucursal2: { value: formData.telefonoSucursal2.trim() }
        })
      );

      await dispatch(checkLogged(user.id));
      setLoading(false);
      setUserDataLoaded(true);
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="mb-3">Datos Comerciales</Form.Label>
        <div className="mb-3">
          {/* Email Comercial */}
          <Form.Label>Emails Comerciales</Form.Label>
          {formData.emailComercial.map((email, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Form.Control
                type="email"
                placeholder={`Email Comercial ${index + 1}`}
                value={email.value}
                onChange={e => {
                  const newEmailComercial = [...formData.emailComercial];
                  newEmailComercial[index].value = e.target.value;
                  setFormData(prev => ({
                    ...prev,
                    emailComercial: newEmailComercial
                  }));
                }}
              />
              {index > 0 && (
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleRemoveEmailComercial(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
          ))}
          <Button
            variant="primary"
            size="sm"
            className="mt-2"
            onClick={handleAddEmailComercial}
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar Email
          </Button>
        </div>
        <div className="mb-3">
          {/* Telefono Comercial */}
          <Form.Label>Teléfonos Comerciales</Form.Label>
          {formData.telefonoComercial.map((telefono, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Form.Control
                type="text"
                placeholder={`Teléfono Comercial ${index + 1}`}
                value={telefono.value}
                onChange={e => {
                  const newTelefonoComercial = [...formData.telefonoComercial];
                  newTelefonoComercial[index].value = e.target.value;
                  setFormData(prev => ({
                    ...prev,
                    telefonoComercial: newTelefonoComercial
                  }));
                }}
              />
              {index > 0 && (
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleRemoveTelefonoComercial(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
          ))}
          <Button
            variant="primary"
            size="sm"
            className="mt-2"
            onClick={handleAddTelefonoComercial}
          >
            <FontAwesomeIcon icon={faPlus} /> Agregar Teléfono
          </Button>
        </div>
        {/* Rest of the form groups */}
        <Form.Label>Teléfono Casa Central</Form.Label>
        <Form.Control
          type="text"
          placeholder="Telefono Casa Central"
          value={formData.telefonoCasaCentral}
          onChange={e =>
            setFormData({ ...formData, telefonoCasaCentral: e.target.value })
          }
        />
        <Form.Label>Teléfono Sucursal 1</Form.Label>
        <Form.Control
          type="text"
          placeholder="Teléfono Sucursal 1"
          value={formData.telefonoSucursal1}
          onChange={e =>
            setFormData({ ...formData, telefonoSucursal1: e.target.value })
          }
        />
        <Form.Label>Teléfono Sucursal 2</Form.Label>
        <Form.Control
          type="text"
          placeholder="Telefono Sucursal 2"
          value={formData.telefonoSucursal2}
          onChange={e =>
            setFormData({ ...formData, telefonoSucursal2: e.target.value })
          }
        />
      </Form.Group>

      {/* ... Other form groups */}

      <div className="d-flex justify-content-end">
        <Button
          size="sm"
          variant="success"
          className="me-1 ms-2"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <Spinner animation="border" size="sm" variant="primary" />
          ) : (
            <span>Guardar</span>
          )}
        </Button>
      </div>
    </Form>
  );
};

FormModificarDatosComercial.propTypes = {
  user: PropTypes.object
};
