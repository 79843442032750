import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  InputGroup,
  Form,
  Row,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getStatusIcon from 'components/common/getStatusIcon';
import AyudaInput from './AyudaInput';
import BuscadorMatriculado from './components/BuscadorMatriculado';
import { FileInput } from './components/FileInput';

const getInputType = (
  input,
  formData,
  handleChange,
  errors,
  isDisabled,
  handleChangeFileMultiple,
  handleRemoveFile
) => {
  switch (input.tipo) {
    case 'textarea':
      return !isDisabled ? (
        <Form.Control
          name={input.nombre}
          onChange={e => handleChange(e, input.nombre)}
          value={formData[input.nombre]?.value}
          aria-label={input.nombre}
          as="textarea"
          rows={3}
          isInvalid={errors[input.nombre]}
        />
      ) : (
        <span className="form-control">
          {formData[input.nombre]?.value || '-'}
        </span>
      );

    case 'select':
      const value = formData[input.nombre]?.value;
      return !isDisabled ? (
        <Form.Select
          name={input.nombre}
          onChange={e => handleChange(e, input.nombre)}
          value={formData[input.nombre]?.value}
          isInvalid={errors[input.nombre]}
        >
          {!formData[input.nombre]?.value && (
            <option value="">Seleccione una opción</option>
          )}
          {input.opciones.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      ) : (
        <span className="form-control">
          {value
            ? input.opciones.find(option => option.value === value)?.label ||
              '-'
            : '-'}
        </span>
      );

    case 'checkbox':
      return !isDisabled ? (
        <InputGroup.Checkbox
          id={input.nombre}
          name={input.nombre}
          onChange={e => handleChange(e, input.nombre)}
          checked={formData[input.nombre]?.value}
        />
      ) : (
        <span className="form-control">
          {formData[input.nombre]?.value ? 'Si' : 'No'}
        </span>
      );

    case 'file':
      return (
        <FileInput
          errors={errors}
          formData={formData}
          handleChangeFileMultiple={handleChangeFileMultiple}
          handleRemoveFile={handleRemoveFile}
          input={input}
          isDisabled={isDisabled}
          inputNombre={input.nombre}
        />
      );

    case 'buscadorMatriculado':
      return (
        <BuscadorMatriculado
          isDisabled={isDisabled}
          matriculadoId={formData[input.nombre]?.value}
          handleChange={handleChange}
          inputNombre={input.nombre}
        />
      );

    default:
      return !isDisabled ? (
        <Form.Control
          name={input.nombre}
          onChange={e => handleChange(e, input.nombre)}
          value={formData[input.nombre]?.value}
          aria-label={input.nombre}
          type={input.tipo}
          isInvalid={errors[input.nombre]}
          className={input.tipo === 'date' ? 'pe-4' : ''}
        />
      ) : (
        <span className="form-control">
          {formData[input.nombre]?.value || '-'}
        </span>
      );
  }
};

const ProcedureInputComponent = ({
  input,
  handleChange,
  formData,
  errors,
  isDisabled,
  isChildren,
  handleChangeFileMultiple,
  handleRemoveFile
}) => {
  let show = true;

  if (isChildren) {
    const [nombrePadre, valorPadre] = input.padre.split(':');
    if (valorPadre) {
      if (formData[nombrePadre]?.value !== valorPadre) {
        show = false;
      }
    }
  }
  const [multiple, setMultiple] = useState(['']);

  const thisIsDisabled =
    isDisabled || input.InputValues?.estado === 'approved' || input.isDisabled;

  useEffect(() => {
    if (
      input.multiple &&
      input.tipo !== 'file' &&
      formData[input.nombre]?.value
    ) {
      setMultiple(formData[input.nombre]?.value.split('/'));
    }
  }, [input]);

  return !show ? null : (
    <>
      <Col
        xs={12}
        lg={
          isChildren ||
          input.tipo === 'textarea' ||
          input.tipo === 'buscadorMatriculado' ||
          input.fluid
            ? 12
            : 6
        }
        className="d-flex flex-column justify-content-end pt-2"
        style={{
          backgroundColor:
            (input.multiple && input.tipo !== 'file' && multiple.length > 1) ||
            (input.hijos && formData[input.nombre]?.value)
              ? 'var(--falcon-300)'
              : '',
          transition: 'background-color 0.5s ease'
        }}
      >
        <InputGroup size="sm">
          {input.tipo !== 'buscadorMatriculado' && (
            <InputGroup.Text
              className="bg-primary text-light"
              style={{ width: input.tipo === 'textarea' ? '100%' : '' }}
            >
              {`${input.titulo}${
                input.multiple && input.tipo !== 'file' ? ' 1' : ''
              }`}
            </InputGroup.Text>
          )}

          {getStatusIcon(
            input.InputValues?.estado,
            formData[input.nombre]?.estado
          )}

          {(!input.multiple || input.tipo === 'file') &&
            getInputType(
              input,
              formData,
              handleChange,
              errors,
              thisIsDisabled,
              handleChangeFileMultiple,
              handleRemoveFile
            )}

          {!errors[input.nombre] &&
            input.requerido?.some(
              req => req === true || formData[req]?.value
            ) &&
            (input.multiple && input.tipo !== 'file'
              ? !formData[input.nombre]?.value?.replaceAll('/', '')
              : input.tipo === 'file'
              ? !formData[input.nombre]?.value?.length &&
                !input.InputValues?.archivos?.length
              : !formData[input.nombre]?.value) && (
              <div
                style={{
                  position: 'absolute',
                  right: input.multiple && input.tipo !== 'file' ? 34 : 7,
                  zIndex: 5
                }}
              >
                <FontAwesomeIcon
                  className="text-danger fs--2"
                  icon="asterisk"
                />
              </div>
            )}

          {input.multiple &&
            input.tipo !== 'file' &&
            multiple.map((item, index) => (
              <Fragment key={`${input.nombre}${index}`}>
                {index === 0 && (
                  <>
                    {!thisIsDisabled ? (
                      <Form.Control
                        name={input.nombre}
                        onChange={e => {
                          const newMultiple = multiple.map((item, i) =>
                            i === index ? e.target.value : item
                          );
                          setMultiple(newMultiple);
                          handleChange(
                            {
                              target: {
                                name: input.nombre,
                                value: newMultiple.join('/')
                              }
                            },
                            input.nombre
                          );
                        }}
                        value={item}
                        aria-label={input.nombre}
                        type={input.tipo}
                        isInvalid={
                          errors[input.nombre] && errors[input.nombre][index]
                        }
                        className={input.tipo === 'date' ? 'pe-4' : ''}
                        size="sm"
                      />
                    ) : (
                      <span className="form-control">{item || '-'}</span>
                    )}

                    {!thisIsDisabled && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Agregar otro {input.titulo.toLowerCase()}
                          </Tooltip>
                        }
                      >
                        <span>
                          <InputGroup.Text
                            className="cursor-pointer bg-primary"
                            style={{ paddingLeft: 6, paddingRight: 6 }}
                            onClick={() => setMultiple([...multiple, ''])}
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              className="text-light"
                            />
                          </InputGroup.Text>
                        </span>
                      </OverlayTrigger>
                    )}

                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex justify-content-end"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {errors[input.nombre] && errors[input.nombre][index]}
                    </Form.Control.Feedback>
                  </>
                )}

                {index !== 0 && (
                  <InputGroup className="mt-2" size="sm">
                    <InputGroup.Text className="bg-primary text-light">
                      {input.titulo + ' ' + (index + 1)}
                    </InputGroup.Text>
                    {!thisIsDisabled ? (
                      <Form.Control
                        name={input.nombre}
                        onChange={e => {
                          const newMultiple = multiple.map((item, i) =>
                            i === index ? e.target.value : item
                          );
                          setMultiple(newMultiple);
                          handleChange(
                            {
                              target: {
                                name: input.nombre,
                                value: newMultiple.join('/')
                              }
                            },
                            input.nombre
                          );
                        }}
                        value={item}
                        aria-label={input.nombre}
                        type={input.tipo}
                        isInvalid={errors[input.nombre]}
                        className={input.tipo === 'date' ? 'pe-4' : ''}
                        size="sm"
                      />
                    ) : (
                      <span className="form-control">{item || '-'}</span>
                    )}

                    {!thisIsDisabled && (
                      <InputGroup.Text
                        className="cursor-pointer bg-danger"
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        onClick={() => {
                          const newMultiple = multiple.filter(
                            (item, i) => i !== index
                          );
                          setMultiple(newMultiple);
                          handleChange(
                            {
                              target: {
                                name: input.nombre,
                                value: newMultiple.join('/')
                              }
                            },
                            input.nombre
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon="xmark-circle"
                          className="text-light"
                        />
                      </InputGroup.Text>
                    )}
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex justify-content-end"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {errors[input.nombre] && errors[input.nombre][index]}
                    </Form.Control.Feedback>
                  </InputGroup>
                )}
              </Fragment>
            ))}

          {!errors[input.nombre]
            ? input.ayuda && (
                <AyudaInput ayuda={input.ayuda} formData={formData} />
              )
            : (!input.multiple || input.tipo === 'file') && (
                <Form.Control.Feedback
                  type="invalid"
                  className="d-flex justify-content-end"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {errors[input.nombre]}
                </Form.Control.Feedback>
              )}
        </InputGroup>

        <Row className="g-1 d-flex align-items-start mt-1">
          {input.hijos &&
            formData[input.nombre]?.value &&
            input.hijos.map(child => (
              <ProcedureInputComponent
                key={child.nombre}
                input={child}
                handleChange={handleChange}
                formData={formData}
                errors={errors}
                isDisabled={isDisabled}
                isChildren={input.fluid ? false : true}
                handleChangeFileMultiple={handleChangeFileMultiple}
                handleRemoveFile={handleRemoveFile}
              />
            ))}
        </Row>
      </Col>
    </>
  );
};
ProcedureInputComponent.propTypes = {
  input: PropTypes.object,
  handleChange: PropTypes.func,
  formData: PropTypes.object,
  errors: PropTypes.object,
  isDisabled: PropTypes.bool,
  isChildren: PropTypes.bool,
  handleChangeFileMultiple: PropTypes.func,
  handleRemoveFile: PropTypes.func
};

export default ProcedureInputComponent;
