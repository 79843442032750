import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderPreview from 'components/common/RenderPreview';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

export const UploadFilesPreviews = ({
  previews,
  handleClickFile,
  colSize,
  inputNombre,
  noDelete,
  isPreviewObject
}) => {
  return (
    <Row>
      {previews.map((file, i) => {
        return (
          <Col
            {...colSize}
            key={`p${i}`}
            className="position-relative d-flex flex-column justify-content-between mb-3"
          >
            {!noDelete && (
              <Button
                name={i}
                size="small"
                variant="link"
                style={{
                  position: 'absolute',
                  top: -8,
                  right: 4,
                  borderRadius: '50%'
                }}
                onClick={() => handleClickFile(inputNombre, file)}
                className="px-1 py-0"
              >
                <FontAwesomeIcon
                  className="p-0 m-0 text-danger bg-white"
                  icon="xmark-circle"
                  style={{ borderRadius: '50%' }}
                />
              </Button>
            )}
            <Card>
              {previews[i] && (
                <RenderPreview
                  preview={isPreviewObject ? previews[i].preview : previews[i]}
                  alt="preview"
                  isSmall
                />
              )}
            </Card>

            <div
              className="w-100 text-dark fs--2 text-center"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {file.name}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};
UploadFilesPreviews.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClickFile: PropTypes.func.isRequired,
  colSize: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number
  }),
  inputNombre: PropTypes.string.isRequired,
  noDelete: PropTypes.bool,
  isPreviewObject: PropTypes.bool
};
