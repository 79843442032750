import { combineReducers } from 'redux';
import authReducer from './authReducer';
import tramiteReducer from './tramiteReducer';
import notificationReducer from './notificationReducer';
import turnosReducer from './turnosReducer';
import eventoReducer from './eventoReducer';
import matriculaReducer from './matriculaReducer';

const rootReducer = combineReducers({
  authReducer,
  tramiteReducer,
  notificationReducer,
  turnosReducer,
  eventoReducer,
  matriculaReducer
});

export default rootReducer;
