import RenderPreview from 'components/common/RenderPreview';
import dayjs from 'dayjs';
import ProptTypes from 'prop-types';
import React from 'react';
import { Card, Col, InputGroup } from 'react-bootstrap';

const Archivo = ({ archivo }) => {
  return (
    <Col xs={12} lg={6} className="position-relative">
      <InputGroup size="sm" className="d-flex" style={{ flexWrap: 'nowrap' }}>
        <InputGroup.Text
          className="bg-primary text-light"
          style={{ overflow: 'hidden', textOverflow: 'ellipsis', flexGrow: 1 }}
        >
          {archivo.titulo}
        </InputGroup.Text>
        <InputGroup.Text className="bg-secondary text-light">
          <strong>{dayjs(archivo.fecha).format('DD/MM/YYYY')}</strong>
        </InputGroup.Text>
      </InputGroup>
      <Card>
        <RenderPreview preview={archivo.archivoUbicacion} alt="preview" />
      </Card>
    </Col>
  );
};

Archivo.propTypes = {
  archivo: ProptTypes.object.isRequired
};

export default Archivo;
