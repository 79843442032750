import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const CambiarPassword = ({ openModal, setOpenModal, email }) => {
  const handleSubmit = async e => {
    e.preventDefault();
    if (email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      try {
        await axios.post(
          process.env.REACT_APP_SERVER + '/auth/recuperar-contrasenia',
          { email }
        );

        toast.success(
          `Un email fue enviado a ${email} con el link para restablecer la contraseña`
        );
        setOpenModal(false);
      } catch (e) {
        toast.error(e.response?.data?.message);
      }
    } else {
      toast.error('El email no es válido');
    }
  };
  return (
    <Modal
      show={openModal}
      onHide={() => setOpenModal(false)}
      contentClassName="border"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Modificar Contraseña</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="text-center">
          <h4 className="mb-0"> Deseas cambiar tu contraseña?</h4>

          <small>
            Se te enviará un mail con un link para que la puedas cambiar tu
            contraseña
          </small>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" size="sm" onClick={() => setOpenModal(false)}>
          No
        </Button>

        <Button variant="success" size="sm" onClick={handleSubmit}>
          Si
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CambiarPassword.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  email: PropTypes.string
};

export default CambiarPassword;
