const licenciaPorPasividad = [
  'No tener deuda.',
  'No estar desarrollando actividad.',
  'Entregar la credencial.',
  'Mínimo de un año como matriculado.',
  'Solo se puede hacer entre el 01/01 y el 31/03 de cada año.'
];

const subsidioPorFallecimiento = [
  'Solicitado por Esposa/o ó hija/o en caso de viudez (Agregar relación parental).',
  'Acta de defunción.',
  'Antigüedad de matrícula superior a un año.',
  'Matrícula en cualquier estado menos “BAJA”.',
  'Sin deudas con el Colegio.',
  'En caso de tener deudas las mismas se descuentan del monto a percibir.',
  'Sin causas ni sanciones del tribunal de ética.',
  'Solicitud iniciada dentro de los 365 días posteriores a la fecha del acta de defunción.'
];

const sitemaFidelita = [
  'Matrícula anual paga.',
  'DDJJ de actividad comercial realizada.',
  'El servicio es de 10 consultas mensuales que no son acumulables.',
  'Sólo se puede realizar este trámite una vez.'
];

const altaMatriculacion = [
  '1º) Comparecer de lunes a viernes en las oficinas del Colegio previa solicitud de turno gestionada telefónicamente y acreditar haber abonado el gasto administrativo por matriculación, acompañando diploma original para cotejo y fotocopia certificada por escribano público en tamaño A4 en la que debe leerse claramente las legalizaciones correspondientes. El título debe contener la legalización del Ministerio de Educación Dirección de Gestión Universitaria (Disp. Nº 18/97 DNGU) tramitada por la universidad de origen y de corresponder la legalización por el Ministerio del Interior tramitada por el interesado. (Nota 1 y Nota 8)',
  '2º) Acompañar certificado analítico original para cotejo y fotocopia en el que debe leerse claramente las legalizaciones correspondientes. El analítico debe contener la legalización del Ministerio de Educación Dirección de Gestión Universitaria (Disp. Nº 18/97 DNGU) tramitada por la universidad de origen y de corresponder la legalización por el Ministerio del Interior tramitada por el interesado. (Nota 1 y Nota 2)',
  '3º) Acreditar identidad con Documento Nacional de Identidad (DNI) exclusivamente y acompañar una fotocopia del mismo. (Nota 3)',
  '4º) Completar formulario de datos personales y ficha de matriculación. (Nota 4)',
  '5º) Traer dos fotos carnet color 4x4 actualizadas e impresas en papel fotográfico.',
  '6º) Constituir un domicilio especial / profesional en la Ciudad Autónoma de Buenos Aires (art. 5º inc. 3 Ley 2340). (Nota 5)',
  '7º) Se debe presentar: Certificado de estadísticas y reincidencia criminal  (solicitar turno en: www.dnrec.jus.gov.ar)Certificado de juicios universales: se gestiona con turno previo en: www.csjn.gov.ar/dgarchivo/tramite, dependencia "Dirección General de Archivos", Sección: Mesa de entradas, Tramite: Presentar documentacion. Dicho trámite debe ir dirigido al Colegio Unico de Corredores Inmobiliarios de la Ciudad de Buenos Aires. (Bajar AQUI y completar el formulario para ser presentado en mesa de entrada de tribunales)NOTA: Los certificados a presentar no pueden superar el plazo de 30 dias corridos a partir de la fecha de emisión de los mismos.Aclaramos que el Certificado de Inhibición será gestionado por CUCICBA (Art. 2 de la ley 20266 y art. 7 de la ley 2340).',
  '8º) Deberán denunciarse todo tipo de incompatibilidades para el ejercicio de la profesión (art. 8º Ley 2340). (Nota 6)',
  '9º) Hacer saber a los solicitantes de matrícula de corredor que además de cumplimentar los requisitos vigentes, deberán acreditar fehacientemente hallarse domiciliados en el ámbito geográfico de la ciudad de Buenos Aires, por más de un año, contado desde la fecha de la petición (art. 33 Ley 20.266 ref. Ley 25.028).',
  '10º) Una vez aprobado el legajo se le enviará al solicitante una nota al domicilio legal declarado informándole de la aprobación de su trámite, invitándolo a abonar el valor de la inscripción vigente ($400.000) más el pago de la matricula anual 2023 ($200.000) más la Fianza 2024 ($3000), total a abonar $603.000 para luego prestar juramento conforme lo establece la Ley 2340. (Nota 7 y Nota 9)'
];

const matriculacionCesantia = [
  'No tener deuda, o haber pagado la primera cuota.',
  'Aprobación del HCD.'
];

const firmaParaCotizacion = [
  '  Presentar cotización original.',
  'Tener alta de actividad comercial.',
  ' Abonar el costo.'
];

const seguroCaucion = [
  'Presentarse antes del 28/02 de cada año, caso contrario adhiere automáticamente a la fianza del Colegio.'
];
const certificadoMatriculaVigente = ['No tener deuda.', 'No tener sanciones.'];
export const requisitos = {
  11: licenciaPorPasividad,
  14: licenciaPorPasividad,
  9: subsidioPorFallecimiento,
  3: sitemaFidelita,
  1: altaMatriculacion,
  10: firmaParaCotizacion,
  8: certificadoMatriculaVigente,
  12: seguroCaucion,
  13: matriculacionCesantia
};
