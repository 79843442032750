import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const estadoInicial = {
  email: '',
  contrasenia: ''
};

const CambiarEmail = ({ openModal, setOpenModal }) => {
  const handleSubmit = async e => {
    e.preventDefault();
    if (
      formData.email &&
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email) &&
      formData.contrasenia
    ) {
      try {
        await axios.post(
          process.env.REACT_APP_SERVER + '/auth/solicitud-cambio-mail',
          { email: formData.email, password: formData.contrasenia }
        );
        toast.success(
          `Un email fue enviado a ${formData.email} con el link para restablecer la contraseña`
        );
        setOpenModal(false);
      } catch (e) {
        toast.error(e.response?.data?.message);
      }
    } else {
      toast.error('El email no es válido');
    }
  };
  const [formData, setFormData] = useState(estadoInicial);

  useEffect(() => {
    setFormData(estadoInicial);
  }, [openModal]);

  return (
    <Modal
      show={openModal}
      onHide={() => setOpenModal(false)}
      contentClassName="border"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Modificar Email</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="text-center">
          <h4 className="mb-0"> Deseas cambiar tu mail?</h4>

          <Form>
            <Form.Group>
              <Form.Label>Nuevo Mail</Form.Label>
              <Form.Control
                type="email"
                name="nuevoEmail"
                placeholder="Email"
                value={formData.email}
                onChange={e =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="contrasenia"
                placeholder="Contraseña"
                value={formData.contrasenia}
                onChange={e =>
                  setFormData({ ...formData, contrasenia: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" size="sm" onClick={() => setOpenModal(false)}>
          No
        </Button>

        <Button variant="success" size="sm" onClick={handleSubmit}>
          Si
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CambiarEmail.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  email: PropTypes.string
};

export default CambiarEmail;
