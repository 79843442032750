import { useEffect } from 'react';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'utils/useQuery';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getMatriculaById } from 'redux/actions/matriculas';
import CryptoJS from 'crypto-js';

const QR = () => {
  const query = useQuery();

  const matriculaId = query.get('m');
  const userId = query.get('u');

  const decrypteUserId = userId
    ? CryptoJS.AES.decrypt(
        userId
          .replaceAll('xMl3Jk', '+')
          .replaceAll('Por21Ld', '/')
          .replaceAll('Ml32', '='),
        process.env.REACT_APP_CRYPTO_SECRET
      ).toString(CryptoJS.enc.Utf8)
    : '';

  const decrypteMatriculaId = matriculaId
    ? CryptoJS.AES.decrypt(
        matriculaId
          .replaceAll('xMl3Jk', '+')
          .replaceAll('Por21Ld', '/')
          .replaceAll('Ml32', '='),
        process.env.REACT_APP_CRYPTO_SECRET
      ).toString(CryptoJS.enc.Utf8)
    : '';

  const dispatch = useDispatch();
  const { matricula } = useSelector(state => state.matriculaReducer);

  useEffect(() => {
    matriculaId && dispatch(getMatriculaById(decrypteMatriculaId));
  }, [matriculaId]);

  useEffect(() => {
    // Cambia el background-color del body cuando el componente se monta
    document.body.style.backgroundColor = '#ffffff';

    // Limpia el cambio cuando el componente se desmonta (opcional)
    return () => {
      document.body.style.backgroundColor = ''; // Restaura el valor original o define uno por defecto
    };
  }, []);

  return (
    <div>
      {matricula ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            WebkitJustifyContent: 'center'
          }}
        >
          <style>
            {`@media print {
    * {
        -webkit-print-color-adjust: exact; /* Para navegadores basados en WebKit */
        color-adjust: exact; /* Para navegadores que soporten esta propiedad */
    }
    
}`}
          </style>
          <div
            style={{
              marginTop: '10px',
              height: '400px',
              padding: '10px',
              width: '900px'
            }}
          >
            <div
              style={{
                border: '40px solid #0f213f',
                backgroundColor: '#0f213f'
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  width: '100%',
                  minWidth: '700px'
                }}
              >
                <div
                  style={{
                    backgroundColor: '#1a386c',
                    width: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    WebkitJustifyContent: 'center',
                    alignItems: 'center',
                    WebkitAlignItems: 'center',
                    transform: 'rotate(-180deg)',
                    WebkitTransform: 'rotate(-180deg)',
                    height: '460px'
                  }}
                >
                  <span
                    style={{
                      fontSize: '100px',
                      lineHeight: '150px',
                      padding: '0',
                      color: '#fff',
                      fontWeight: '600',
                      writingMode: 'vertical-rl',
                      WebkitWritingMode: 'vertical-rl',
                      transform: 'translate(20px, 5px)',
                      WebkitTransform: 'translate(20px, 5px)'
                    }}
                  >
                    2024
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    WebkitFlexDirection: 'column',
                    justifyContent: 'space-between',
                    WebkitJustifyContent: 'space-between',
                    padding: '20px',
                    width: '100%',
                    paddingRight: '10px'
                  }}
                >
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img
                      src="/logo_large.png"
                      alt=""
                      style={{ width: '150px' }}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      WebkitAlignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        color: '#1a386c',
                        flex: '1',
                        flexDirection: 'column',
                        maxWidth: '480px'
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            width: '130px',
                            flexShrink: 0
                          }}
                        >
                          <label
                            style={{ fontSize: '18px', fontWeight: 'bold' }}
                          >
                            Nombre:
                          </label>
                        </div>
                        <div
                          style={{
                            fontSize: '15px',
                            fontWeight: '700',
                            textAlign: 'left',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            flex: '1',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            marginTop: '3px'
                          }}
                        >
                          <label
                            style={{ fontSize: '15px', fontWeight: '700' }}
                          >
                            {matricula.nombre}
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            width: '130px',
                            flexShrink: 0
                          }}
                        >
                          <label
                            style={{ fontSize: '18px', fontWeight: 'bold' }}
                          >
                            Matrícula:
                          </label>
                        </div>
                        <div
                          style={{
                            fontSize: '15px',
                            fontWeight: '700',
                            textAlign: 'left',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            flex: '1',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            marginTop: '3px'
                          }}
                        >
                          <label
                            style={{ fontSize: '15px', fontWeight: '700' }}
                          >
                            {matricula.matriculaNumero}
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            width: '130px',
                            flexShrink: 0
                          }}
                        >
                          <label
                            style={{ fontSize: '18px', fontWeight: 'bold' }}
                          >
                            Dirección:
                          </label>
                        </div>
                        <div
                          style={{
                            fontSize: '15px',
                            fontWeight: '700',
                            textAlign: 'left',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            flex: '1',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            marginTop: '3px'
                          }}
                        >
                          <label
                            style={{ fontSize: '15px', fontWeight: '700' }}
                          >
                            {matricula.domicilioComercial}, CABA
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            width: '130px',
                            flexShrink: 0
                          }}
                        >
                          <label
                            style={{ fontSize: '18px', fontWeight: 'bold' }}
                          >
                            Vencimiento:
                          </label>
                        </div>
                        <div
                          style={{
                            fontSize: '15px',
                            fontWeight: '700',
                            textAlign: 'left',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            flex: '1',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            marginTop: '3px'
                          }}
                        >
                          <label
                            style={{ fontSize: '15px', fontWeight: '700' }}
                          >
                            {dayjs(matricula.vencimiento).format('DD/MM/YYYY')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        src={matricula.qr}
                        alt="qr"
                        style={{ width: '150px' }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      fontSize: '12px',
                      color: '#1a386c',
                      textAlign: 'center'
                    }}
                  >
                    <p>
                      Inmobiliaria matriculada en CUCICBA (Colegio Profesional
                      Inmobiliario) <br />
                      Adolfo Alsina 1382. CABA <br />
                      Tel: 4124-6060 - www.colegioinmobiliario.org.ar <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Container>
          <Row className="g-3 mb-3">
            <Col xs={12} xl={12} xxl={12}>
              <Row className="g-3">
                <Col xs={12}></Col>
                <Card>
                  <Card.Body>
                    <div
                      className="mb-3"
                      style={{ width: '100%', textAlign: 'left' }}
                    >
                      <img
                        src="/logo_large.png"
                        alt=""
                        style={{ width: '150px' }}
                      />
                    </div>
                    <div className="text-center mb-5">
                      <h1 className="display-5 mb-2">
                        La oblea buscada no existe.
                      </h1>
                      <p>
                        Por favor comunicate con la oficina de Matriculación.
                      </p>
                    </div>
                    <div>
                      <h6 className="fs-11 text-center">
                        Inmobiliaria matriculada en CUCICBA (Colegio Profesional
                        Inmobiliario) <br />
                        Adolfo Alsina 1382. CABA <br />
                        Tel: 4124-6060 - www.colegioinmobiliario.org.ar <br />
                      </h6>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default QR;
