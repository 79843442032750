import tramites from 'assets/json/tramites';
import Flex from 'components/common/Flex';
import ProcedureInputComponent from 'components/tramites/inputComponents/ProcedureInputComponent';
import {
  flatInputs,
  regex
} from 'components/tramites/inputComponents/ProcedureInputs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { tramiteCrearExterno } from 'redux/actions/tramite';
import comprimirImagen from 'utils/comprimirImagen';
/* import ReCAPTCHA from 'react-google-recaptcha'; */

export const Formulario = ({ secciones }) => {
  const dispatch = useDispatch();
  const { tipoTramite } = useSelector(state => state.tramiteReducer);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState(false);
  /*   const [captcha, setCaptcha] = useState(false); */

  /* const onChangeCaptcha = value => {
    setCaptcha(value);
  }; */

  useEffect(() => {
    if (secciones) {
      const newInputs = [];
      secciones.forEach(seccion => {
        newInputs.push(...seccion.inputs);
      });
      setInputs(newInputs);
    }
  }, [secciones]);

  const validate = async inputs => {
    let newErrors = {};

    for (const input of inputs) {
      if (input.validaciones && formData[input.nombre]?.value) {
        for (const validation in input.validaciones) {
          switch (validation) {
            case 'regex':
              if (input.validaciones[validation]) {
                if (input.multiple) {
                  const values = formData[input.nombre].value.split('/');
                  values.forEach((value, index) => {
                    if (!regex[input.validaciones[validation]].test(value)) {
                      if (!newErrors[input.nombre]) {
                        newErrors[input.nombre] = [];
                      }

                      newErrors[input.nombre][
                        index
                      ] = `${input.titulo} invalido`;
                    }
                  });
                } else {
                  if (
                    input.validaciones[validation] &&
                    !regex[input.validaciones[validation]].test(
                      formData[input.nombre].value
                    )
                  ) {
                    newErrors[input.nombre] = `${input.titulo} invalido`;
                  }
                }
              }
              break;

            case 'min':
              if (input.validaciones[validation]) {
                if (input.multiple) {
                  const values = formData[input.nombre].value.split('/');
                  values.forEach((value, index) => {
                    if (value.length < input.validaciones[validation]) {
                      if (!newErrors[input.nombre]) {
                        newErrors[input.nombre] = [];
                      }

                      if (input.validaciones.min === input.validaciones.max) {
                        newErrors[input.nombre][
                          index
                        ] = `${input.titulo} debe tener ${input.validaciones[validation]} caracteres`;
                      } else {
                        newErrors[input.nombre][
                          index
                        ] = `${input.titulo} debe tener al menos ${input.validaciones[validation]} caracteres`;
                      }
                    }
                  });
                } else {
                  if (
                    input.validaciones[validation] &&
                    formData[input.nombre].value.length <
                      input.validaciones[validation]
                  ) {
                    if (input.validaciones.min === input.validaciones.max) {
                      newErrors[
                        input.nombre
                      ] = `${input.titulo} debe tener ${input.validaciones[validation]} caracteres`;
                    } else {
                      newErrors[
                        input.nombre
                      ] = `${input.titulo} debe tener al menos ${input.validaciones[validation]} caracteres`;
                    }
                  }
                }
              }

              break;

            case 'max':
              if (input.validaciones[validation]) {
                if (input.multiple) {
                  const values = formData[input.nombre].value.split('/');
                  values.forEach((value, index) => {
                    if (value.length > input.validaciones[validation]) {
                      if (!newErrors[input.nombre]) {
                        newErrors[input.nombre] = [];
                      }

                      if (input.validaciones.min === input.validaciones.max) {
                        newErrors[input.nombre][
                          index
                        ] = `${input.titulo} debe tener ${input.validaciones[validation]} caracteres`;
                      } else {
                        newErrors[input.nombre][
                          index
                        ] = `${input.titulo} debe tener menos de ${input.validaciones[validation]} caracteres`;
                      }
                    }
                  });
                } else {
                  if (
                    input.validaciones[validation] &&
                    formData[input.nombre].value.length >
                      input.validaciones[validation]
                  ) {
                    if (input.validaciones.min === input.validaciones.max) {
                      newErrors[
                        input.nombre
                      ] = `${input.titulo} debe tener ${input.validaciones[validation]} caracteres`;
                    } else {
                      newErrors[
                        input.nombre
                      ] = `${input.titulo} debe tener menos de ${input.validaciones[validation]} caracteres`;
                    }
                  }
                }
              }
              break;

            case 'number':
              if (input.validaciones[validation]) {
                const value = formData[input.nombre].value;
                if (isNaN(value)) {
                  newErrors[
                    input.nombre
                  ] = `${input.titulo} debe ser un numero`;
                } else {
                  const number = Number(value);

                  if (
                    input.validaciones[validation].min &&
                    input.validaciones[validation].max &&
                    (number < input.validaciones[validation].min ||
                      number > input.validaciones[validation].max)
                  ) {
                    newErrors[
                      input.nombre
                    ] = `${input.titulo} debe estar entre ${input.validaciones[validation].min} y ${input.validaciones[validation].max}`;
                  } else if (
                    input.validaciones[validation].min &&
                    number < input.validaciones[validation].min
                  ) {
                    newErrors[
                      input.nombre
                    ] = `${input.titulo} debe ser mayor o igual a ${input.validaciones[validation].min}`;
                  } else if (
                    input.validaciones[validation].max &&
                    number > input.validaciones[validation].max
                  ) {
                    newErrors[
                      input.nombre
                    ] = `${input.titulo} debe ser menor o igual a ${input.validaciones[validation].max}`;
                  }
                }
              }

              break;

            default:
              break;
          }
        }
      }

      for (const req of input.requerido) {
        if (input.multiple && input.tipo !== 'file') {
          const values = formData[input.nombre].value?.split('/');
          if (values) {
            values.forEach((value, index) => {
              if (req === true && !value) {
                if (!newErrors[input.nombre]) {
                  newErrors[input.nombre] = [];
                }
                newErrors[input.nombre][index] = 'Campo requerido';
              } else {
                if (typeof req === 'string' && req.includes(':')) {
                  const [nombrePadre, valorPadre] = req.split(':');
                  if (
                    formData[nombrePadre] &&
                    formData[nombrePadre].value === valorPadre &&
                    !value
                  ) {
                    if (!newErrors[input.nombre]) {
                      newErrors[input.nombre] = [];
                    }

                    newErrors[input.nombre][index] = 'Campo requerido';
                  }
                } else if (
                  (formData[req] && formData[req].value) ||
                  (tipoTramite.seccionesSinFiltrar?.some(seccion =>
                    seccion.inputs.some(
                      i => i.nombre === req && i.InputValues?.value
                    )
                  ) &&
                    !value)
                ) {
                  if (!newErrors[input.nombre]) {
                    newErrors[input.nombre] = [];
                  }

                  newErrors[input.nombre][index] = 'Campo requerido';
                }
              }
            });
          } else if (
            req === true ||
            (formData[req] && formData[req].value) ||
            tipoTramite.seccionesSinFiltrar?.some(seccion =>
              seccion.inputs.some(i => i.nombre === req && i.InputValues?.value)
            )
          ) {
            newErrors[input.nombre] = ['Campo requerido'];
          }
        } else if (input.tipo === 'file') {
          if (
            req === true &&
            !formData[input.nombre]?.value?.length &&
            !input.InputValues?.archivos?.length
          ) {
            newErrors[input.nombre] = 'Campo requerido';
          } else if (
            (formData[req]?.value ||
              tipoTramite.seccionesSinFiltrar?.some(seccion =>
                seccion.inputs.some(
                  i => i.nombre === req && i.InputValues?.value
                )
              )) &&
            !formData[input.nombre]?.value?.length &&
            !input.InputValues?.archivos?.length
          ) {
            newErrors[input.nombre] = 'Campo requerido';
          }
        } else {
          if (req === true && !formData[input.nombre]?.value) {
            newErrors[input.nombre] = 'Campo requerido';
          } else {
            if (typeof req === 'string' && req.includes(':')) {
              const [nombrePadre, valorPadre] = req.split(':');
              if (
                formData[nombrePadre] &&
                formData[nombrePadre].value === valorPadre &&
                !formData[input.nombre]?.value
              ) {
                newErrors[input.nombre] = 'Campo requerido';
              }
            } else if (
              (formData[req]?.value ||
                tipoTramite.seccionesSinFiltrar?.some(seccion =>
                  seccion.inputs.some(
                    i => i.nombre === req && i.InputValues?.value
                  )
                )) &&
              !formData[input.nombre]?.value
            ) {
              newErrors[input.nombre] = 'Campo requerido';
            }
          }
        }
      }

      if (input.tipo === 'file') {
        const files = formData[input.nombre]?.files;

        if (files?.length) {
          const filesArray = [];
          let fileError = ``;

          for (const file of files) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file, document);

            const result = await new Promise((resolve, reject) => {
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });

            const acceptFileTypes = input.opciones?.multimedia
              ? [
                  'application/pdf',
                  'image/',
                  'video/',
                  'audio/',
                  'application/msword',
                  'application/vnd.ms-excel',
                  'application/vnd.ms-powerpoint',
                  'text/plain',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                ]
              : ['application/pdf', 'image/'];

            if (acceptFileTypes.some(type => file.type.includes(type))) {
              if (file.type.includes('image/')) {
                if (file.size > 10485760) {
                  const archivo = await comprimirImagen(file);
                  filesArray.push(archivo);
                } else {
                  filesArray.push(file);
                }
              } else if (file.type === 'application/pdf') {
                filesArray.push(file);
                if (file.size > 10485760) {
                  fileError += `\nUn PDF supera el tamaño máximo permitido - ${file.name}`;
                } else {
                  const files = new Blob([result], {
                    type: 'application/pdf'
                  });
                  const text = await files.text();

                  if (
                    text.includes('Encrypt') ||
                    text
                      .substring(text.lastIndexOf('<<'), text.lastIndexOf('>>'))
                      .includes('/Encrypt')
                  ) {
                    fileError += `\nUn PDF está encriptado - ${file.name}`;
                  }
                }
              } else {
                filesArray.push(file);
                if (file.size > 10485760) {
                  fileError += `\nUn archivo supera el tamaño máximo permitido - ${file.name}`;
                }
              }
            } else {
              fileError += `\nTipo de archivo no permitido - ${file.name}`;
            }
          }

          if (fileError) {
            newErrors[input.nombre] = fileError;
          }

          setFormData(a => ({
            ...a,
            [input.nombre]: { ...a[input.nombre], files: filesArray }
          }));
        }
      }

      if (input.hijos) {
        newErrors = { ...newErrors, ...(await validate(input.hijos)) };
      }
    }

    return newErrors;
  };

  const handleChange = (e, inputNombre) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: {
        value: type === 'checkbox' ? e.target.checked : value,
        inputNombre
      }
    });

    setErrors(state => ({ ...state, [name]: undefined }));
  };

  const handleChangeFileMultiple = async (e, inputNombre, noMultiple) => {
    let arr = Array.from(e.target.files);
    if (noMultiple) {
      arr = [arr[0]];
    } else {
      arr = [
        ...arr.filter(
          file => !formData[inputNombre]?.files?.some(f => f.name === file.name)
        ),
        ...(formData[inputNombre]?.files || [])
      ];
    }

    if (arr.length) {
      const previewArray = arr.map(file => {
        const extension = file.name.substring(file.name.lastIndexOf('.'));
        let preview = URL.createObjectURL(file);

        preview = preview + extension;

        return preview;
      });

      setFormData({
        ...formData,
        [inputNombre]: {
          ...formData[inputNombre],
          value: previewArray,
          files: arr
        }
      });
    }
  };

  const handleRemoveFile = (inputNombre, preview) => {
    const files = formData[inputNombre].files;
    const value = formData[inputNombre].value;

    const index = value.indexOf(preview);
    value.splice(index, 1);
    files.splice(index, 1);

    setFormData({
      ...formData,
      [inputNombre]: {
        ...formData[inputNombre],
        value,
        files
      }
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const errors = await validate(inputs);
    setErrors(errors);

    const newInputs = flatInputs(inputs, formData);

    if (Object.keys(errors).length === 0 && !loading /* && captcha */) {
      setLoading(true);
      const data = newInputs
        .map(input => ({
          ...formData[input.nombre],
          estado: 'sent',
          inputNombre: input.nombre,
          tipo: input.tipo
        }))
        .filter(input => {
          return (
            input.value !== '' ||
            newInputs.find(i => i.nombre === input.inputNombre).InputValues
              ?.value
          );
        });

      const dataNoFile = data
        .filter(input => input.tipo !== 'file' && input.value)
        .map(input => {
          // eslint-disable-next-line no-unused-vars
          const { tipo, value, ...rest } = input;
          return {
            ...rest,
            value: value === true ? 'true' : value === false ? 'false' : value
          };
        });

      const dataFile = data
        .filter(input => input.tipo === 'file')
        .map(input => ({
          files: input.files,
          inputNombre: input.inputNombre
        }));

      await dispatch(
        tramiteCrearExterno(tramites.denunciaExterna, dataNoFile, dataFile)
      );

      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        {secciones.map((seccion, index) => {
          return (
            <div key={index} className="mt-4">
              <h5>
                <strong>{seccion.titulo}</strong>
              </h5>

              <div>
                <Row className="d-flex align-items-start">
                  {seccion.inputs.map(campo => {
                    return (
                      <ProcedureInputComponent
                        key={campo.id}
                        formData={formData}
                        errors={errors}
                        input={campo}
                        handleChange={handleChange}
                        handleChangeFileMultiple={handleChangeFileMultiple}
                        handleRemoveFile={handleRemoveFile}
                      />
                    );
                  })}
                </Row>
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="mt-4 d-flex justify-content-center">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          onChange={onChangeCaptcha}
        />
      </div> */}

      <Flex justifyContent="end" className="mt-3 mx-0">
        <Flex direction="column" justifyContent="center" alignItems="end">
          <Button type="submit" disabled={loading /* || !captcha */}>
            Enviar
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
Formulario.propTypes = {
  secciones: PropTypes.array
};
