import * as types from '../actions/matriculas/types';

const initialState = {
  loading: true,
  error: null,
  matricula: null
};

const matriculaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MATRICULA_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.MATRICULA_GET_BY_USER_ID:
      return {
        ...state,
        loading: false,
        matricula: action.payload
      };

    case types.MATRICULA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default matriculaReducer;
