import tramites from 'assets/json/tramites';
import RenderPreview from 'components/common/RenderPreview';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { checkLastProcedureState, tramiteCreate } from 'redux/actions/tramite';
import instructivo from '../../../assets/documents/instructivoDDJJ.pdf';
const InfoDdjjActividadComercial = () => {
  const { user } = useSelector(state => state.authReducer);
  const { lastProcedure } = useSelector(state => state.tramiteReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isRegistred = !!user;

  const handleIniciarTramite = () => {
    dispatch(
      tramiteCreate(
        { tipoId: tramites.ddjjActividadComercial, userId: user.id },
        navigate
      )
    );
  };
  useEffect(() => {
    // TODO: Fetch if the user have a process in progress
    user &&
      dispatch(
        checkLastProcedureState(user.id, tramites.ddjjActividadComercial)
      );
  }, [user]);

  const handleNavigateOwnProcedure = () => {
    navigate('/tramites/' + lastProcedure.id);
  };

  return (
    <div className="container">
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.2rem'
        }}
      >
        <h4
          style={{
            fontWeight: 700,
            marginTop: '20px'
          }}
        >
          Declaracion Jurada de Actividad Comercial
        </h4>
        <div>
          {isRegistred ? (
            (lastProcedure && lastProcedure?.estado === 'pendiente') ||
            lastProcedure?.estado === 'finalizado' ? (
              <Button
                type="button"
                className="btn btn-primary"
                style={{
                  cursor: 'pointer'
                }}
                onClick={handleNavigateOwnProcedure}
              >
                Ir a mi tramite
              </Button>
            ) : lastProcedure && lastProcedure.estado === 'rechazado' ? (
              <>
                <Button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={handleIniciarTramite}
                >
                  Iniciar nuevo tramite
                </Button>
                <Button
                  type="button"
                  className="btn btn-danger"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '1rem'
                  }}
                  onClick={handleNavigateOwnProcedure}
                >
                  Ir a mi tramite rechazado
                </Button>
              </>
            ) : (
              <Button
                type="button"
                className="btn btn-primary"
                style={{
                  cursor: 'pointer'
                }}
                onClick={handleIniciarTramite}
              >
                Iniciar Tramite
              </Button>
            )
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div>
                <Link to="/login">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    Iniciar sesión
                  </button>
                </Link>
                <Link to="/register">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px'
                    }}
                  >
                    Registrarse
                  </button>
                </Link>
              </div>
              <small>Debe estar registrado para iniciar ese tramite</small>
            </div>
          )}
        </div>
      </div>
      <RenderPreview
        preview={instructivo}
        alt="Intructivo para Realizar el Trámite Declaración Jurada de Actividad Comercial"
      />
    </div>
  );
};

export default InfoDdjjActividadComercial;
