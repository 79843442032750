import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, InputGroup } from 'react-bootstrap';
import ViewFileLinkArrayModal from 'components/common/ViewFileLinkArrayModal';
import { useDispatch } from 'react-redux';
import { deleteDocument, tramiteGetById } from 'redux/actions/tramite';

export const FileInput = ({
  isDisabled,
  formData,
  input,
  handleChangeFileMultiple,
  errors,
  handleRemoveFile,
  inputNombre
}) => {
  const inputRefMultiple = useRef();
  const inputRefSingle = useRef();

  const dispatch = useDispatch();

  const handleRemoveFileServer = async (inputNombre, file) => {
    const { documentId } = file;
    await dispatch(deleteDocument(documentId));
    await dispatch(tramiteGetById(input.InputValues?.tramiteId, true));
  };

  return !isDisabled ? (
    <>
      {input.multiple ? (
        <>
          <Form.Control
            name={input.nombre}
            onChange={e => handleChangeFileMultiple(e, input.nombre)}
            aria-label={input.nombre}
            type={input.tipo}
            isInvalid={errors[input.nombre]}
            accept={
              input.opciones?.multimedia
                ? 'application/pdf,image/*,video/*,audio/*,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                : 'application/pdf,image/*'
            }
            multiple
            ref={inputRefMultiple}
            style={{ display: 'none' }}
          />

          <InputGroup.Text
            className="p-0"
            style={{ flexGrow: 1, overflow: 'hidden' }}
          >
            <Button
              size="sm"
              style={{ backgroundColor: 'var(--falcon-input-color)' }}
              className="p-0 w-100 h-100 rounded-0"
              onClick={() => inputRefMultiple.current.click()}
            >
              Elegir archivos
            </Button>
          </InputGroup.Text>
        </>
      ) : (
        <>
          <Form.Control
            name={input.nombre}
            onChange={e => handleChangeFileMultiple(e, input.nombre, true)}
            aria-label={input.nombre}
            type={input.tipo}
            isInvalid={errors[input.nombre]}
            ref={inputRefSingle}
            style={{ display: 'none' }}
            accept={
              input.opciones?.multimedia
                ? 'application/pdf,image/*,video/*,audio/*,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                : 'application/pdf,image/*'
            }
          />

          <InputGroup.Text
            className="p-0"
            style={{ flexGrow: 1, overflow: 'hidden' }}
          >
            <Button
              size="sm"
              style={{ backgroundColor: 'var(--falcon-input-color)' }}
              className="p-0 w-100 h-100 rounded-0"
              onClick={() => inputRefSingle.current.click()}
            >
              Elegir archivo
            </Button>
          </InputGroup.Text>
        </>
      )}

      {input.InputValues?.archivos && (
        <span className="form-control w-100">
          <small>Archivos subidos</small>
          <ViewFileLinkArrayModal
            previews={input.InputValues?.archivos?.map(archivo => ({
              preview: archivo.archivoUbicacion,
              documentId: archivo.id
            }))}
            alt={input.nombre}
            isUpload
            handleRemoveFile={handleRemoveFileServer}
            inputNombre={inputNombre}
            isPreviewObject
            noDelete={
              formData[input.nombre].estado === 'sent' ||
              formData[input.nombre].estado === 'approved'
            }
          />
        </span>
      )}

      {formData[input.nombre]?.value && (
        <span className="form-control w-100">
          <small>Archivos en cola</small>
          <ViewFileLinkArrayModal
            previews={formData[input.nombre].value}
            alt={input.nombre}
            isUpload
            handleRemoveFile={handleRemoveFile}
            inputNombre={inputNombre}
            noDelete={
              formData[input.nombre].estado === 'sent' ||
              formData[input.nombre].estado === 'approved'
            }
          />
        </span>
      )}
    </>
  ) : (
    <span className="form-control">
      {input.InputValues?.archivos ? (
        <ViewFileLinkArrayModal
          previews={input.InputValues.archivos.map(
            archivo => archivo.archivoUbicacion
          )}
          alt={input.nombre}
        />
      ) : (
        '-'
      )}
    </span>
  );
};

FileInput.propTypes = {
  isDisabled: PropTypes.bool,
  formData: PropTypes.object,
  input: PropTypes.object,
  handleChangeFileMultiple: PropTypes.func,
  errors: PropTypes.object,
  handleRemoveFile: PropTypes.func,
  inputNombre: PropTypes.string
};
