import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { checkLogged, updateDatos } from 'redux/actions/auth';

const datosIniciales = {
  nombre: '',
  apellido: '',
  dni: '',
  telefonoParticular: '',
  celularParticular: '',
  domicilioReal: '',
  codigoPostalReal: ''
};

export const FormModificarDatos = ({ user }) => {
  const [formData, setFormData] = useState(datosIniciales);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData({
      nombre: user?.datos.nombre.value,
      apellido: user?.datos.apellido.value,
      dni: user?.datos.dni.value,
      telefonoParticular: user?.datos.telefonoParticular?.value,
      celularParticular: user?.datos.celularParticular?.value,
      domicilioReal: user?.datos.domicilioReal?.value,
      codigoPostalReal: user?.datos.codigoPostalReal?.value
    });
  }, [user]);
  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true);

      await dispatch(
        updateDatos(user.id, {
          ...user.datos,
          nombre: {
            value: formData.nombre
          },
          apellido: {
            value: formData.apellido
          },
          dni: {
            value: formData.dni
          },
          telefonoParticular: {
            value: formData.telefonoParticular
          },
          celularParticular: {
            value: formData.celularParticular
          },
          domicilioReal: {
            value: formData.domicilioReal
          },
          codigoPostalReal: {
            value: formData.codigoPostalReal
          }
        })
      );
      await dispatch(checkLogged(user.id));
      setLoading(false);
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="mb-3">Datos Personales</Form.Label> <br />
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          type="text"
          placeholder="Nombre"
          value={formData.nombre}
          onChange={e =>
            setFormData(prev => ({
              ...prev,
              nombre: e.target.value
            }))
          }
        />
        <Form.Label>Apellido</Form.Label>
        <Form.Control
          type="text"
          placeholder="Apellido"
          value={formData.apellido}
          onChange={e =>
            setFormData(prev => ({
              ...prev,
              apellido: e.target.value
            }))
          }
        />
        <Form.Label>DNI</Form.Label>
        <Form.Control
          type="number"
          placeholder="DNI"
          value={formData.dni}
          onChange={e =>
            setFormData(prev => ({
              ...prev,
              dni: e.target.value
            }))
          }
        />
        <Form.Label>Teléfono Particular</Form.Label>
        <Form.Control
          type="number"
          placeholder="Telefono"
          value={formData.telefonoParticular}
          onChange={e =>
            setFormData(prev => ({
              ...prev,
              telefonoParticular: e.target.value
            }))
          }
        />
        <Form.Label>Celular Particular</Form.Label>
        <Form.Control
          type="number"
          placeholder="Celular"
          value={formData.celularParticular}
          onChange={e =>
            setFormData(prev => ({
              ...prev,
              celularParticular: e.target.value
            }))
          }
        />
        <Form.Label>Dirección Real</Form.Label>
        <Form.Control
          type="text"
          placeholder="Direccion"
          value={formData.domicilioReal}
          onChange={e =>
            setFormData(prev => ({
              ...prev,
              domicilioReal: e.target.value
            }))
          }
        />
        <Form.Label>Código Postal</Form.Label>
        <Form.Control
          type="number"
          placeholder="Codigo Postal"
          value={formData.codigoPostalReal}
          onChange={e =>
            setFormData(prev => ({
              ...prev,
              codigoPostalReal: e.target.value
            }))
          }
        />
      </Form.Group>
      <div className="d-flex justify-content-end">
        <Button
          size="sm"
          variant="success"
          className="me-1 ms-2"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <Spinner animation="border" size="sm" variant="primary" />
          ) : (
            <span>Guardar</span>
          )}
        </Button>
      </div>
    </Form>
  );
};
FormModificarDatos.propTypes = {
  user: PropTypes.object
};
