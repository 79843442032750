import axios from 'axios';
import * as types from './types';

const serverUrl = process.env.REACT_APP_SERVER;
const versionApi = '/v2';

export const getMatriculaByUserId = userId => async dispatch => {
  try {
    dispatch({ type: types.MATRICULA_REQUEST });
    const res = await axios.get(serverUrl + versionApi + '/oblea/' + userId);
    dispatch({ type: types.MATRICULA_GET_BY_USER_ID, payload: res.data });
  } catch (err) {
    dispatch({ type: types.MATRICULA_FAIL, payload: err });
  }
};

export const getMatriculaById = id => async dispatch => {
  try {
    dispatch({ type: types.MATRICULA_REQUEST });
    const res = await axios.get(serverUrl + versionApi + '/obleas/oblea/' + id);
    dispatch({ type: types.MATRICULA_GET_BY_USER_ID, payload: res.data });
  } catch (err) {
    dispatch({ type: types.MATRICULA_FAIL, payload: err });
  }
};
