import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const InfoCuentaBancaria = ({ show, setShow }) => {
  return (
    <Modal show={show} size="lg" centered onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Información Cuenta Bancaria</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Banco Santander Río</strong>
        </p>
        <ul>
          <li>Cuit: 33-71103057-9</li>
          <li>CBU: 0720000720000003079036</li>
          <li>Alias: ORO.NAVIO.DIA</li>
          <li>Cta. Cte Nº: 000-030790/3</li>
          <li>Sucursal Nº: 000 Casa Matriz</li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

InfoCuentaBancaria.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};

export default InfoCuentaBancaria;
