import axios from 'axios';
import { CustomCard } from 'components/common/CustomCard';
import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Oblea = () => {
  const { user } = useSelector(state => state.authReducer);

  const [oblea, setOblea] = useState(null);
  const [hasOblea, setHasOblea] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(async () => {
    if (user?.datos?.oblea) {
      setHasOblea(true);
      if (user?.datos?.domicilioCasaCentral)
        setOptions(prev => [
          ...prev,
          { value: 'domicilioCasaCentral', description: 'Casa Central' }
        ]);
      if (user?.datos?.domicilioSucursal1)
        setOptions(prev => [
          ...prev,
          { value: 'domicilioSucursal1', description: 'Sucursal 1' }
        ]);
      if (user?.datos?.domicilioSucursal2)
        setOptions(prev => [
          ...prev,
          { value: 'domicilioSucursal2', description: 'Sucursal 2' }
        ]);
    } else {
      setLoading(false);
    }
  }, [user]);

  const handleChange = async e => {
    if (e.target.value !== '') {
      setLoading(true);
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_SERVER + '/pdf',
          {
            responseType: 'blob'
          }
        );
        setOblea(data);
        setLoading(false);

        const pdfBlob = new Blob([data], { type: 'application/pdf' });

        // Crea una URL para el blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Abre el PDF en una nueva pestaña
        window.open(pdfUrl, '_blank');
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <CustomCard title="Oblea" icon="print">
      <Card.Body>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : hasOblea ? (
          <div>
            <label>Seleccione la sucursal para imprimir la oblea</label>
            <select
              name=""
              id="sucursales"
              className="form-select"
              onChange={handleChange}
            >
              <option value="">-- Seleccione Sucursal --</option>
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.description}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div>
            <p>No tenés oblea</p>
            <p>
              Para obtener tu oblea, tenés que realizar el trámite de Alta de
              Matriculación y la declaración jurada de Actividad Comercial
            </p>
          </div>
        )}
      </Card.Body>
    </CustomCard>
  );
};

export default Oblea;
